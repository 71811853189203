import { useSelector } from 'react-redux'

import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
// routing
import Routes from 'routes'

// defaultTheme
import themes from 'themes'

// import 'react-toastify/dist/ReactToastify.css'
// import { ToastContainer } from 'react-toastify'

// project imports
import NavigationScroll from 'layout/NavigationScroll'
import { useEffect } from 'react'
import ToastCustom from 'ui-component/Toast/Toast'
import { useState } from 'react'

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization)
    // useEffect(() =>{
    //     setTimeout(() =>{
    //         document.getElementsByTagName('iframe')[0].style.display = 'none'
    //     }, 1000)
    // }, [])
    const [toast, setToast] = useState({ open: true, severity: 'success', title: '123', closeAlert: handleCloseAlert })
    function handleCloseAlert() {
        setToast((prev) => ({
            ...prev,
            open: false
        }))
    }
    return (
        <>
            <ToastCustom open={toast.open} handleCloseAlert={toast.closeAlert} severity={toast.severity} title={toast.title} />

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                        {/* <ToastContainer /> */}
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </>

    )
}

export default App
