import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import historyApi from 'apis/history.api';

import { handleMiddlewareLoadingAction } from 'store/helper'

const getLiveApi = createAsyncThunk(
    'live/get',
    async (query) => handleMiddlewareLoadingAction(() => historyApi.getLiveHistory(query), 'live/get')
)

const initialState = {
    live: undefined
}

const slotSlice = createSlice({
    name: 'live',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getLiveApi.fulfilled , (state, {payload}) =>{
            state.live = payload
        })
    }
})

export const thunkAction = {
    getLiveApi
}

export default slotSlice.reducer
