/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import losingApi from 'apis/losing.api'
import { handleMiddlewareLoadingAction } from 'store/helper'

export const getLosing = createAsyncThunk(
    'losing',
    (query) => {
        const resp = handleMiddlewareLoadingAction(() => losingApi.getLosing(query), 'losing')
        return resp
    }
)

const losingHistory = createSlice({
    name: 'losingHistory',
    initialState: {
        data: null,
    },
    extraReducers: {
        [getLosing.fulfilled]: (state, action) => {
            state.data = action.payload
        },
    }
})
const losingHistoryReducer = losingHistory.reducer
export default losingHistoryReducer