import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import actionUserConnectorApi from 'apis/actionUserConnectorApi'
import { payloadCreatorAndLoading } from 'store/helper'

export const getUserConnector = createAsyncThunk(
    'user/connector',
    payloadCreatorAndLoading(
        actionUserConnectorApi.getUserConnector,
        'user/connector'
    )
)

const userConnector = createSlice({
    name: 'userConnector',
    initialState: {
        data: {
            active_sort: '',
            filter: [],
            header: {},
            data: []
        }
    },
    extraReducers: {
        [getUserConnector.fulfilled]: (state, action) => {
            if (action.payload.code === 200) {
                state.data = {
                    active_sort: action.payload.active_sort,
                    filter: action.payload.head.sort_type,
                    header: action.payload.header,
                    data: action.payload.list.data
                }
            }
        }
    }
})

const userConnectorReducer = userConnector.reducer
export default userConnectorReducer
