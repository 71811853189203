import React, { useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const WrappedCk = styled('div')`
    width: 100%;

    .ck-content {
        height: 300px;
    }
`

function CkEditorCustomized({ data, getDataProps }) {
    const [editorData, setEditorData] = React.useState(data ?? '')

    const handleEditorChange = (event, editor) => {
        const newData = editor.getData()
        setEditorData(newData)

        // Call the parent component's callback function to pass the data
        getDataProps && getDataProps(newData)
    }

    useEffect(() => {
        data !== undefined && setEditorData(data)
    }, [data])

    return (
        <WrappedCk>
            <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onReady={(editor) => {}}
                onInit={(editor) => {
                    // editor.ui.view.editable.extendTemplate({
                    //   toolbar: [
                    //     {
                    //       name: 'basicstyles',
                    //       items: [
                    //         'Bold',
                    //         'Italic',
                    //         'Underline',
                    //         'Strikethrough , FontBackgroundColor'
                    //       ]
                    //     },
                    //     { name: 'list', items: ['BulletedList', 'NumberedList'] },
                    //     { name: 'paragraph', items: ['BlockQuote'] }
                    //   ]
                    // })
                }}
                onChange={handleEditorChange}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
            />
        </WrappedCk>
    )
}

CkEditorCustomized.propTypes = {
    getDataProps: PropTypes.func,
    data: PropTypes.string
}

export default CkEditorCustomized
