import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import managementApi from 'apis/managementBetting.api'

import { handleMiddlewareLoadingAction } from 'store/helper'

const getAll = createAsyncThunk('management/all', async (query) =>
    handleMiddlewareLoadingAction(
        () => managementApi.getManagementBetting(query),
        'management/all'
    )
)

const initialState = {
    all: undefined
}

const managementSlice = createSlice({
    name: 'management',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAll.fulfilled, (state, { payload }) => {
                state.all = payload
            })
            .addCase(getAll.rejected, (state) => {
                state.all = {
                    ...state.all,
                    data: {
                        data: []
                    }
                }
            })
    }
})

export const thunkAction = {
    getAll
}

export default managementSlice.reducer
