import http from 'views/utilities/http'

const slotEndpoint = 'evo/sale_management'
const liveEndpoint = 'evo/betting/statistics_by_game'
const userEndpoint = 'evo/betting/statistics_by_user'
const shopEndpoint = 'evo/betting/statistics_by_shop'

const historyApi = {
    getSlotHistory: (query) => http.get(`${slotEndpoint}`, {params: query}),
    getLiveHistory: (query) => http.get(`${liveEndpoint}`, {params: query}),
    getUserHistory: (query) => http.get(`${userEndpoint}`, {params: query}),
    getShopHistory: (query) => http.get(`${shopEndpoint}`, {params: query}),
}

export default historyApi
