import http from 'views/utilities/http'

export const URL_LOGIN = '/login'
export const URL_REGISTER = 'register'
export const URL_LOGOUT = 'logout'
export const URL_REFRESH_TOKEN = 'refresh-access-token'

const authApi = {
    registerAccount(body) {
        return http.post(URL_REGISTER, body)
    },
    login(body) {
        return http.post(URL_LOGIN, body)
    },
    logout() {
        return http.get(URL_LOGOUT)
    }
}

export default authApi
