import http from 'views/utilities/http'

export const URL_GET_IP = 'evo/member/login_history/'
export const URL_GET_MESSAGE = 'evo/member/message/'
export const URL_GET_DEPOSIT = 'evo/member/deposit'
export const URL_GET_WITHDRAW = 'evo/member/withdraw'
export const URL_CORE = 'evo/'
export const URL_PAYMENT_HISTORY = 'evo/money/payment_history'
export const URL_COLLECT_HISTORY = 'evo/money/payment_history'
export const URL_POINT_EXCHANGE_HISTORY = 'evo/member/point/change'
export const URL_PROFIT_AND_LOSE = 'evo/member/transaction'
const userHistoryAction = {
    getIP(params) {
        return http.get(`${URL_GET_IP}${params.id}?page=${params.page}`)
    },
    getMessage(params){
        return http.get(`${URL_GET_MESSAGE}${params.id}?page=${params.page}`)
    },
    getDepositMoney(params){
        return http.get(`${URL_GET_DEPOSIT}`, {params: params})
    },
    getWithdrawMoney(params){
        return http.get(`${URL_GET_WITHDRAW}`, {params: params})
    },
    getPaymentHistory(params){
        return http.get(`${URL_PAYMENT_HISTORY}`, {params: params})
    },
    getCollectHistory(params){
        return http.get(`${URL_COLLECT_HISTORY}`, {params: params})
    },
    getPointExchangeHistory(params){
        return http.get(`${URL_POINT_EXCHANGE_HISTORY}`, {params: params})
    },
    getProfitLose(params){
        return http.get(`${URL_PROFIT_AND_LOSE}`, {params: params})
    },
    handleActionApi(params){
        return http.post(`${URL_CORE}${params}`)
    }
}

export default userHistoryAction