import { Typography, formControlClasses } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { formatNumber } from "views/utilities/moneyConvert";
import './style.css'
const UserHistoryBox = ({ me }) => {
    useEffect(() => [me])
    const boxGroup = [
        { title: '구분 ', color: '#5BC0DE', value: me?.data?.title },
        { title: '접속ID ', color: '#F0AD4E', value: me?.data?.mb_id },
        { title: '닉네임 ', color: '#5BC0DE', value: me?.data?.mb_name },
        { title: '보유머니', color: '#F0AD4E', value: formatNumber(me?.data?.mb_money || 0) },
        { title: '보유포인트', color: '#D9534F', value: formatNumber(me?.data?.mb_point || 0) },
    ]
    return (
        <>
            {me.data &&
                boxGroup.map((v, i) => {
                    return (
                        <div key={i} >
                            <Box
                                sx={{
                                    width: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    bgcolor: 'white',
                                    color: '#282f3a',
                                    padding: '0.47rem 0.6rem',
                                    lineHeight: '1.5rem',
                                    borderLeft: `5px solid ${v.color}`,
                                    borderRadius: '3px'
                                }}
                            >
                                <Typography
                                    sx={{ textAlign: 'start', marginBottom: '10px' }}
                                    variant='h6'
                                    color='inherit'
                                >
                                    {v.title}
                                </Typography>

                                <Typography
                                    sx={{ textAlign: 'center' }}
                                    variant='h4'
                                    color='inherit'
                                >
                                    {v.value}
                                </Typography>
                            </Box>
                        </div>
                    )
                })
            }
           
        </>
    )
}
export default UserHistoryBox