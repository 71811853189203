import http from 'views/utilities/http'

const dashboardEndpoint = 'evo/dashboard'
const deleteInqiry = `cscenter/delete`
const deleteMessage = `message/delete`

const dashboardApi = {
    getSlotHistory: (query) => http.get(`${dashboardEndpoint}`, {params: query}),
    deleteInqiry: (id) => http.get(`${deleteInqiry}/${id}`),
    deleteMessage: (id) => http.get(`${deleteMessage}/${id}`),
}

export default dashboardApi
