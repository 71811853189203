import http from 'views/utilities/http'

export const URL_ME = '/me'
export const URL_USER_INFORT = 'evo/member/detail/'
const getMeApi = {
    getMe(params) {
        return http.get(URL_ME, params)
    },
    getUserInfor(id) {
        return http.get(`${URL_USER_INFORT}${id}`)
    }
}

export default getMeApi
