import PropTypes from 'prop-types'
import React from 'react'
// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, ButtonBase, Typography } from '@mui/material'
import './header.css'
// project imports
import LogoSection from '../LogoSection'
import SearchSection from './SearchSection'
import ProfileSection from './ProfileSection'
import NotificationSection from './NotificationSection'
import ModaRecharge from '../../../ui-component/Modal/ModalRecharge'
// assets
import { IconMenu2 } from '@tabler/icons'
import CustomButton from 'views/utilities/CustomButton'
import ModalPay from 'ui-component/Modal/ModalPay'
import BtnGroup from './ButtonGroupMobile/ButtonGroupMobile'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getMe } from 'store/me.slice'
import ModalExchangePoint from 'ui-component/Modal/ModalExchangePoint'
import ToastCustom from 'ui-component/Toast/Toast'
import ModalHelp from 'ui-component/Modal/ModalHelp'
import Settlement from 'ui-component/Modal/Settlement.jsx'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme()
    const [openModalRecharge, setOpenModalRecharge] = React.useState(false)
    const [openModalPay, setOpenModalPay] = React.useState(false)
    const [openModalChangePoint, setOpenModalChangePoint] =
        React.useState(false)
    const [openModalHelp, setOpenModalHelp] = React.useState(false)
    const [openSettlement, setOpenSettlement] = React.useState(false)

    const handleClickOpen = () => {
        setOpenModalRecharge(true)
    }
    const handleClickOpenPay = () => {
        setOpenModalPay(true)
    }
    const handleClickOpenChangePoint = () => {
        setOpenModalChangePoint(true)
    }
    const handleClickOpenHelp = () => {
        setOpenModalHelp(true)
    }
    const dispatch = useDispatch()
    const me = useSelector((state) => state.meReducer)
    useEffect(() => {
        dispatch(getMe())
    }, [])
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box
                    component='span'
                    sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
                >
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant='rounded'
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color='inherit'
                    >
                        <IconMenu2 stroke={1.5} size='1.3rem' />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            <SearchSection />

            <Box>
                <BtnGroup
                    className='mobile-btn-group'
                    handleClickOpen={handleClickOpen}
                    handleClickOpenPay={handleClickOpenPay}
                    handleClickOpenChangePoint={handleClickOpenChangePoint}
                    handleClickOpenHelp={handleClickOpenHelp}
                    openSettlement={() => setOpenSettlement(true)}
                />
            </Box>

            <ModaRecharge
                open={openModalRecharge}
                setOpen={setOpenModalRecharge}
                me={me}
            />
            <ModalPay open={openModalPay} setOpen={setOpenModalPay} me={me} />
            <ModalExchangePoint
                open={openModalChangePoint}
                setOpen={setOpenModalChangePoint}
                me={me}
            />
            <ModalHelp
                open={openModalHelp}
                setOpen={setOpenModalHelp}
                me={me}
            />
            <Settlement
                open={openSettlement}
                setOpen={(val) => setOpenSettlement(val ?? true)}
                me={me}
            />
            {/* notification & profile */}
            {/* <NotificationSection />
            <ProfileSection /> */}
        </>
    )
}

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
}

export default Header
