import React from 'react'
import { Box, Button, Typography } from '@mui/material'

function CustomButton({ bgcolor, title, data, colorText, onClick, disabled }) {
    return (
        <>
            <Button disabled={disabled} onClick={onClick} sx={{ padding: '0' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: disabled ? 'gray' : bgcolor,
                        color: colorText,
                        padding: '0.47rem 0.6rem',
                        borderRadius: '5px'
                    }}
                    fullWidth
                >
                    {title && (
                        <Typography variant='subtitle1' color='inherit'>
                            {title}
                        </Typography>
                    )}
                    {!title && <Box sx={{ p: 1.15 }} />}
                </Box>
            </Button>
        </>
    )
}

export default CustomButton
