/* eslint-disable prettier/prettier */
// import styled from 'styled-components';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const DatePickerCustomized = styled(DatePicker)`
    padding: 5px;
    .MuiFormLabel-root {
        font-size: 13px !important;
        transform: translate(10px, 10px);
        line-height: 1.4em;
    }
    .MuiInputBase-input {
        font-size: 13px !important;
        padding: 15px 12px !important;
    }
    .MuiInputBase-multiline {
        padding: 4px 0 5px !important;
    }

    .Mui-disabled {
        cursor: not-allowed;
    }

    .MuiInputLabel-shrink {
        transform: translate(14px, -9px) scale(0.75);
    }
    .MuiOutlinedInput-notchedOutline{
        border-radius: 0px;
    }
    
`;
