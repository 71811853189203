/* eslint-disable no-undef */
import axios from 'axios'
import config from 'constants/config'
import LocaStorage from 'constants/localStorage'
// import { toast } from 'react-toastify';

// Gọi getUser> cần token
// Gọi porducts: không cần token
// Thường là vậy chứ ko phải lúc nào cũng cần token

class Http {
    constructor() {
        this.instance = axios.create({
            baseURL: config.baseUrl,
            timeout: 300000,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        this.instance.interceptors.response.use(
            (response) => {
                const result = { ...response.data, status: response.status }
                return result
            },
            ({ response }) => {
                if (response.status === 401 || response.message ==='Unauthenticated') {
                    // toast.error(response.data.message, {
                    //     position: 'top-center',
                    //     autoClose: 3000
                    // });
                    localStorage.removeItem('accessToken')
                    window.location.assign('/login')
                }
                const result = { ...response.data, status: response.status }
                return Promise.reject(result)
            }
        )
        this.instance.interceptors.request.use(
            (config) => {
                const accessToken = localStorage.getItem(
                    LocaStorage.accessToken
                )

                if (accessToken) {
                    // config.headers.authorization = accessToken
                    config.headers.Authorization = accessToken
                }
                return config
            },
            (error) => {
                return Promise.reject(error.response)
            }
        )

        this.instanceUpload = axios.create({
            baseURL: config.baseUrl,
            timeout: 10000,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        this.instanceUpload.interceptors.request.use(
            (config) => {
                const accessToken = localStorage.getItem(
                    LocaStorage.accessToken
                )

                if (accessToken) {
                    config.headers.authorization = accessToken
                }
                return config
            },
            (error) => {
                return Promise.reject(error.response)
            }
        )
    }
    get(url, config = null) {
        return this.instance.get(url, config)
    }
    post(url, data, config = null) {
        return this.instance.post(url, data, config)
    }
    put(url, data, config = null) {
        return this.instance.put(url, data, config)
    }
    delete(url, data, config = null) {
        return this.instance.delete(url, {
            data,
            ...config
        })
    }
    upload(url, data, config = null) {
        return this.instanceUpload.post(url, data, config)
    }
}

const http = new Http()
export default http
