import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import actionPointChangeApi from 'apis/actionPointChangeApi.api'
import { payloadCreatorAndLoading } from 'store/helper'

export const getPointChangeList = createAsyncThunk(
    'pointChange/list',
    payloadCreatorAndLoading(
        actionPointChangeApi.getPointChangeApi,
        'pointChange/list'
    )
)

const pointChangeListSlice = createSlice({
    name: 'pointChange',
    initialState: {
        data: {
            filter: {},
            data: []
        }
    },
    extraReducers: {
        [getPointChangeList.fulfilled]: (state, action) => {
            if (action.payload.code === 200) {
                state.data = {
                    filter: action.payload.header,
                    data: action.payload.list.data
                }
            }
        }
    }
})

const pointChangeSliceReducer = pointChangeListSlice.reducer
export default pointChangeSliceReducer
