/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { CircularProgress, Pagination } from '@mui/material'
import { useState } from 'react'
import { useMemo } from 'react'
import ModalMessage from 'ui-component/Modal/ModalShowMess'
function StickyHeadTable({
    dataTable,
    pageChange = (p) => p + 1,
    paginate = false,
    disabled,
    currentPage = 1,
    actionRow
}) {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(30)
    const [openModal, setOpenModal] = useState(false)
    const [dataRow, setDataRow] = useState({})

    // handle page
    const handleChangePage = (event, newPage) => {
        if (currentPage === newPage + 1) return
        setPage(newPage)
        pageChange(newPage + 1)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const clicedData = useMemo(() => {
        return paginate
            ? dataTable?.rows
            : dataTable?.rows?.slice(
                rowsPerPage * page,
                rowsPerPage * (page + 1)
            )
    }, [page, dataTable?.rows])
    return (
        <>
            <ModalMessage
                open={openModal}
                setOpen={setOpenModal}
                title={dataRow?.msg_subject}
                content={dataRow?.msg_memo}
            />
            <div style={{position: 'relative', marginBottom: 3, boxShadow: 1, padding: 4 }}>
                {
                    disabled && <CircularProgress sx={{
                        position: 'absolute',
                        left: 'calc(50% - 40px)',
                        top: 'calc(50% - 40px)',
                        zIndex: 20,

                    }} />
                }
                {dataTable && (
                    // <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <>
                        <TableContainer sx={{ maxHeight: '70vh', overflow: disabled? 'hidden': 'auto' }}>
                            <Table stickyHeader aria-label='sticky table'>
                                <TableHead >
                                    {dataTable?.top?.length && (
                                        <TableRow>
                                            {dataTable?.top?.map((column) => (
                                                <TableCell
                                                    colSpan={column.colSpan}
                                                    sx={{
                                                        background: 'orange',
                                                        color: 'white'
                                                    }}
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth:
                                                            column.minWidth
                                                    }}
                                                   
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        {dataTable?.columns?.map(
                                            (column, e) => {
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth:
                                                                column.minWidth,
                                                            background:
                                                                'whitesmoke'
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                )
                                            }
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{opacity: disabled? '0.5': '1' }}>
                                    {clicedData?.map((row, i) => {
                                        return (
                                            <TableRow
                                                hover
                                                role='checkbox'
                                                tabIndex={-1}
                                                key={i}
                                            >
                                                {dataTable?.columns?.map(
                                                    (column, index) => {
                                                        let value =
                                                            row[column?.id]
                                                        if (
                                                            column?.id?.includes(
                                                                '&'
                                                            )
                                                        ) {
                                                            value = row
                                                        }
                                                        console.log( value)
                                                        return (
                                                            <TableCell
                                                                key={index}
                                                                align={
                                                                    column?.align
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        actionRow &&
                                                                        !column?.id?.includes(
                                                                            '&'
                                                                        )
                                                                    ) {
                                                                        setDataRow(
                                                                            row
                                                                        )
                                                                        setOpenModal(
                                                                            true
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                {column?.render
                                                                    ? column?.render(
                                                                        value
                                                                    )
                                                                    : column?.id ===
                                                                        'no_'
                                                                        ? i + 1
                                                                        : value}
                                                                {
                                                                }
                                                            </TableCell>
                                                        )
                                                    }
                                                )}
                                            </TableRow>
                                        )
                                    })}
                                    {dataTable?.footer?.length &&
                                        dataTable?.footer?.map((column) => (
                                            <TableCell
                                                colSpan={column.colSpan}
                                                sx={{
                                                    background: 'orange',
                                                    color: 'black',
                                                    fontWeight: 'bold'
                                                }}
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {!paginate ? (
                            <TablePagination
                                component='div'
                                count={dataTable?.rows?.length || 1}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                rowsPerPageOptions={[rowsPerPage]}
                                onPageChange={(_, pageNumber) =>
                                    handleChangePage(_, pageNumber)
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                disabled={disabled}
                            />
                        ) : (
                            <>
                                <Pagination
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        pt: '20px'
                                    }}
                                    component='div'
                                    count={
                                        dataTable?.rows &&
                                            dataTable?.total
                                    }
                                    onChange={(_, pageNumber) => {
                                        handleChangePage(_, pageNumber - 1 || 0)
                                    }}
                                    variant='outlined'
                                    disabled={disabled}
                                    page={currentPage}
                                />
                            </>
                        )}
                        {/* </Paper> */}
                    </>
                )}
            </div>
        </>
    )
}

export default React.memo(StickyHeadTable)
