/* eslint-disable prettier/prettier */
// assets
import {
    IconBrandChrome,
    IconHelp,
    IconDeviceDesktop,
    IconUsers,
    IconFileCheck,
    IconChartAreaLine,
    IconArrowBack,
    IconChartBar,
    IconAdjustmentsAlt,
    IconDeviceMobileMessage,
    IconMessage
} from '@tabler/icons'
import { path } from 'constants/path'

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconDeviceDesktop,
    IconUsers,
    IconFileCheck,
    IconChartAreaLine,
    IconArrowBack,
    IconChartBar
}

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const MainMenu = {
    id: 'Main Menu',
    type: 'group',
    children: [
        {
            id: 'DeviceDesktop',
            title: '메인 ',
            type: 'item',
            url: path.dashboard,
            icon: icons.IconDeviceDesktop,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: '회원관리',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'userList',
                    title: '회원 목록',
                    type: 'item',
                    url: path.userList,
                    breadcrumbs: false
                },
                {
                    id: 'userConnector',
                    title: '접속자 목록',
                    type: 'item',
                    url: path.userConnector,
                    breadcrumbs: false
                },
                {
                    id: 'userRollingConversion',
                    title: '포인트 전환기록',
                    type: 'item',
                    url: path.userRollingConversion,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'betting',
            title: '베팅 및 롤링현황',
            type: 'collapse',
            icon: icons.IconFileCheck,

            children: [
                {
                    id: 'slot',
                    title: '베팅 현황(슬롯)',
                    type: 'item',
                    url: '/betting/slot'
                },
                {
                    id: 'casino',
                    title: '베팅 현황(카지노)',
                    type: 'item',
                    url: '/betting/casino'
                    // breadcrumbs: false
                },
                {
                    id: 'slot2',
                    title: '베팅 현황(슬+카)   ',
                    type: 'item',
                    url: '/betting/slot&casino'
                    // breadcrumbs: false
                }
            ]
        },
        {
            id: 'history',
            title: '게임 기록 통계',
            type: 'collapse',
            icon: icons.IconChartAreaLine,

            children: [
                {
                    id: 'historySlot',
                    title: '일자별 베팅현황',
                    type: 'item',
                    url: path.historySlot
                },
                {
                    id: 'historyLive',
                    title: '일자별 게임',
                    type: 'item',
                    url: path.historyLive
                },
                {
                    id: 'historyUser',
                    title: '일자별 사용자별',
                    type: 'item',
                    url: path.historyUser
                },
                {
                    id: 'historyShop',
                    title: '일자별 매장별',
                    type: 'item',
                    url: path.historyShop
                },
                {
                    id: 'historyAllGame',
                    title: '전체 게임별',
                    type: 'item',
                    url: path.historyAllGame
                },
                {
                    id: 'historyAllUser',
                    title: '전체 사용자별',
                    type: 'item',
                    url: path.historyAllUser
                }
                // {
                //     id: 'historyDate',
                //     title: '일자별 게임별',
                //     type: 'item',
                //     url: '/betting-page',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'historyUser',
                //     title: '일자별 사용자별',
                //     type: 'item',
                //     url: '/betting-page',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'historyAll',
                //     title: '전체 게임별',
                //     type: 'item',
                //     url: '/betting-page',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'historyAllTotal',
                //     title: '전체 사용자별',
                //     type: 'item',
                //     url: '/betting-page',
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'management-betting',
            title: '베팅 관리',
            type: 'collapse',
            icon: IconAdjustmentsAlt,
            children: [
                {
                    id: 'management-betting',
                    title: '카지노, 슬롯 전체 내역',
                    type: 'item',
                    url: path.managementBettingAll
                },
                {
                    id: 'management-betting',
                    title: '카지노, 슬롯 적중 내역',
                    type: 'item',
                    url: path.managementBettingWin
                },
                {
                    id: 'management-betting',
                    title: '카지노, 슬롯 낙첨 내역',
                    type: 'item',
                    url: path.managementBettingLose
                },
                {
                    id: 'management-betting',
                    title: '카지노, 슬롯 취소 내역',
                    type: 'item',
                    url: path.managementBettingCancel
                },
                {
                    id: 'management-betting',
                    title: '카지노 내역',
                    type: 'item',
                    url: path.managementBettingCasino
                },
                {
                    id: 'management-betting',
                    title: '슬롯 내역',
                    type: 'item',
                    url: path.managementBettingSlot
                }
            ]
        },
        {
            id: 'agent-note',
            title: '최근 1:1 상담',
            type: 'item',
            icon: IconDeviceMobileMessage,
            url: path.agentNote
        },
        {
            id: 'message',
            title: '쪽지함',
            type: 'item',
            icon: IconMessage,
            url: path.message
        },
        {
            id: 'losing',
            title: '루징정산',
            type: 'collapse',
            icon: icons.IconChartBar,
            children: [
                {
                    id: 'losing',
                    title: '루징 정산 통계',
                    type: 'item',
                    url: path.losing
                    // breadcrumbs: false
                }
            ]
        },
        {
            id: 'logout',
            title: '로그아웃',
            type: 'item',
            url: '',
            icon: icons.IconArrowBack,
            breadcrumbs: false
        }
    ]
}

export default MainMenu
