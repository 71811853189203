class Path {
    constructor() {
        this.notFound = '*'
        this.home = '/'
        this.login = '/login'
        this.register = '/register'
        this.dashboard = '/dashboard'
        this.user = '/user'
        this.userInfo = this.user + '/infor'

        this.history = '/history'

        this.historySlot = this.history + '/slot'
        this.historyLive = this.history + '/live'
        this.historyUser = this.history + '/user'
        this.historyShop = this.history + '/shop'
        this.historyAllGame = this.history + '/all-game'
        this.historyAllUser = this.history + '/all-user'

        this.userList = this.user + '/list'
        this.userConnector = this.user + '/connector'
        this.userRollingConversion = this.user + '/rolling-conversion'
        this.losing = '/losing'
        /////////////////////////////
        //path common

        this.home = '/'
        this.login = '/login'

        this.register = '/register'
        this.setting = '/setting'
        this.user = '/user/list'
        this.board = '/board'
        this.store = '/store'
        this.visitors = '/visitors'
        this.virtualConfig = '/virtual/config'
        this.question = '/question'
        this.message = '/message'
        this.stats = '/stats'
        this.game = '/game'
        this.betting = '/betting'
        this.dashboard = '/dashboard'
        this.userHistory = '/user-history'

        //
        this.managementBetting = '/management-betting'
        this.managementBettingAll = this.managementBetting + '/all'
        this.managementBettingWin = this.managementBetting + '/win'
        this.managementBettingLose = this.managementBetting + '/lose'
        this.managementBettingCancel = this.managementBetting + '/cancel'
        this.managementBettingCasino = this.managementBetting + '/casino'
        this.managementBettingSlot = this.managementBetting + '/slot'
        //
        this.agentNote = '/agentnote'
        this.message = '/message'
    }
}

export const path = new Path()
