import { createSlice } from '@reduxjs/toolkit';
const toastSlice = createSlice({
    name: 'toast',
    initialState: {
        toast: {
            severity: '',
            title: '',
            open: false,
        }
    },
    reducers: {
        setOpenToast: (state, action) => {
            state.open = action.payload;
        },
        setSeverityAndTitle: (state, action) => {
            state.severity = action.payload.severity;
            state.title = action.payload.title;
        },
    }
})

export const { setOpenToast, setSeverityAndTitle } = toastSlice.actions;
export default toastSlice.reducer