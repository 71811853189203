import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePickerCustomized } from './style';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useState } from 'react';

function DateRangeCustomzied({ onChangeStartDate, onChangeEndDate, startDate, endDate }) {
    const handleChangeStartDay = (value) => {
        onChangeStartDate(dayjs(value));
        // setValueStartDate(dayjs(value))
    };

    const handleChangeEndDay = (value) => {
        onChangeEndDate(dayjs(value));
        // setValueEndDate(dayjs(value))
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerCustomized
                value={startDate}
                format="YYYY-MM-DD"
                // label="시작일"
                onChange={handleChangeStartDay}
                defaultValue={startDate}
            />
            {endDate && <DatePickerCustomized
                value={endDate}
                format="YYYY-MM-DD"
                // label="종료일"
                onChange={handleChangeEndDay}
            /> }
        </LocalizationProvider>
    );
}
DateRangeCustomzied.propTypes = {
    onChangeStartDate: PropTypes.func,
    onChangeEndDate: PropTypes.func,
    startDate: PropTypes.object,
    endDate: PropTypes.object
};

export default DateRangeCustomzied;
