import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import historyApi from 'apis/history.api';

import { handleMiddlewareLoadingAction } from 'store/helper'

const getShopApi = createAsyncThunk(
    'shop/get',
    async (query) => handleMiddlewareLoadingAction(() => historyApi.getShopHistory(query), 'shop/get')
)

const initialState = {
    shop: undefined
}

const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getShopApi.fulfilled , (state, {payload}) =>{
            state.shop = payload
        })
    }
})

export const thunkAction = {
    getShopApi
}

export default shopSlice.reducer
