/* eslint-disable no-useless-catch */
/* eslint-disable prettier/prettier */
import { dispatch } from '.'
import { actions } from './loader.slice'

export const handleMiddlewareLoadingAction = async (callback, type, reject) => {
    dispatch(actions.startLoad(type))
    try {
        const resp = await callback()
        return resp
    } catch (error) {
        if (reject) return reject(error)
        return Promise.reject(error)
    } finally {
        dispatch(actions.endLoad(type))
    }
}

export const payloadCreatorAndLoading =
    (asyncFunc, type) => async (arg, thunkAPI) => {
        dispatch(actions.startLoad(type))
        try {
            const res = await asyncFunc(arg)
            return res
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        } finally {
            dispatch(actions.endLoad(type))
        }
    }
