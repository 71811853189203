/* eslint-disable prettier/prettier */
import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import DateRangeCustomzied from "ui-component/DateRangeCustomized";
import MainCard from "ui-component/cards/MainCard";
import StickyHeadTable from 'ui-component/Table';
import { useState } from "react";
import dayjs from "dayjs";
import './style.css'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getLosing } from "./losing.slice";
import { formatDate } from "views/utilities/formatDate";
import { formatNumber } from "views/utilities/moneyConvert";
import Loader from "ui-component/Loader";

const Losing = () => {
    const today = dayjs();
    const yesterday = today.subtract(1, 'day');
    const [valueDatePicker, setValueDatePicker] = useState({
        startDate: yesterday,
        endDate: today
    });
    const [inpValue, setInpValue] = useState('')
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const { data } = useSelector(state => state.losingHistoryReducer);
    const { loader } = useSelector(state => state.loader)
    useEffect(() => {
        dispatch(getLosing({ sdate: formatDate(valueDatePicker.startDate), edate: formatDate(valueDatePicker.endDate), page: page }))
    }, [page])
    useEffect(() => {

    }, [data])
    const onChangeStartDatePicker = (startDayValue) => {
        setValueDatePicker((prev) => ({
            ...prev,
            startDate: dayjs(startDayValue)
        }));
    };
    const onChangeEndDatePicker = (endDayValue) => {
        setValueDatePicker((prev) => ({ ...prev, endDate: dayjs(endDayValue) }));
    };
    const headers = [
        { id: 'id', label: '아이디', minWidth: 80 },
        { id: 'reg_date', label: '배팅 시간', minWidth: 150 },
        { id: 'bet', label: '배팅', minWidth: 80, render: (value) => <>{formatNumber(value)}</> },
        { id: 'win', label: '이긴금액', minWidth: 100, render: (value) => <>{formatNumber(value)}</> },
        { id: 'rolling', label: '롤링', minWidth: 80 },
        { id: 'sort_text', label: '상태', minWidth: 80 },
        { id: 'round_id', label: '회차ID', minWidth: 80 },
        { id: 'game_title', label: '게임명', minWidth: 150 },
        { id: 'vendor', label: '회사명', minWidth: 80 },
        { id: 'type', label: '타입', minWidth: 80 },
        { id: 'losing', label: '루징', minWidth: 80 },
    ]
    const handleSearch = () => {
        dispatch(getLosing({ sdate: formatDate(valueDatePicker.startDate), edate: formatDate(valueDatePicker.endDate), keyfield: 'mb_id', key: inpValue }))
    }
    const pageChange = (e) => {
        setPage(e)
    }
    const boxSummary = [
        { title: 'BET', color: '#C82333', data: data?.head?.totals?.bet },
        { title: 'WIN', color: '#73DFD8', data: data?.head?.totals?.win },
        { title: 'LOSING', color: 'black', data: data?.head?.totals?.losing }
    ]
    function handleInputChange(e) {
        setInpValue(e)
    }
    return (
        <>
                <>
                    <MainCard className="box-card">
                        {boxSummary.map((v, i) => {
                            return (
                                <>
                                    <Box
                                        sx={{
                                            width: '33%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '150px',
                                            color: '#282f3a',
                                            // padding: '0.47rem 0.6rem',
                                            lineHeight: '1.5rem',
                                            border: '1px solid black',
                                        }}
                                    >
                                        <Typography
                                            sx={{ textAlign: 'center', marginBottom: '10px', borderBottom: '1px solid black', bgcolor: v.color, height: '50px', color: 'white' }}
                                            variant='h6'
                                            color='inherit'
                                            className="header-box"
                                        >
                                            {v.title}
                                        </Typography>

                                        <Typography
                                            sx={{ textAlign: 'center' }}
                                            variant='h4'
                                            color='inherit'
                                            className="content-box"
                                        >
                                            {v.data}
                                        </Typography>
                                    </Box>
                                </>
                            )
                        })}

                    </MainCard>
                    <MainCard sx={{height: '100%'}} MainCard className="table-card">
                        <div>
                            <div className="date-filter">
                                <DateRangeCustomzied
                                    onChangeStartDate={onChangeStartDatePicker}
                                    onChangeEndDate={onChangeEndDatePicker}
                                    startDate={valueDatePicker.startDate}
                                    endDate={valueDatePicker.endDate}
                                />
                                <TextField
                                    // type="number"
                                    placeholder="아이디"
                                    id="outlined-read-only-input"
                                    value={inpValue}
                                    onChange={(e) => {
                                        handleInputChange(e.target.value);
                                    }}
                                    sx={{ mr: 1 }}
                                    inputProps={{
                                    
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { handleSearch() }
                                    }}
                                />
                                <Button disabled={loader.isLoading} style={{ background: '#5C4AC7' }} variant="contained" onClick={handleSearch}>검색</Button>
                            </div>
                            <StickyHeadTable dataTable={{ columns: headers, rows: data?.list?.data, total: data?.list?.last_page }} pageChange={pageChange} disabled={loader.isLoading} paginate currentPage={page} />
                        </div>
                    </MainCard>
                </>
        </>
    )
}
export default Losing
