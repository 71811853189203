import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {  setOpenToast, setSeverityAndTitle } from "./toast.slice";

const ToastCustom = () => {
    const dispatch = useDispatch();
    const toast  = useSelector((state) => state.toastReducer);

    const handleCloseAlert = () => {
        dispatch(setOpenToast(false));
        dispatch(setSeverityAndTitle({ severity: '', title: '' }))
    };

    return (
        <Snackbar open={toast?.open} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity={toast?.severity} onClose={handleCloseAlert}>
                {toast?.title}
            </Alert>
        </Snackbar>

    )
}
export default ToastCustom;