import http from 'views/utilities/http'

const agentNoteEndpoint = 'cscenter'
const deleteInqiry = `cscenter/delete`
const deleteMessage = `message/delete`

const agentNoteApi = {
    getAgentNoteHistory: (query) => http.get(`${agentNoteEndpoint}`, {params: query}),
    getAgentNoteDetail: (id) => http.get(`${agentNoteEndpoint}/${id}`),
    deleteInqiry: (id) => http.get(`${deleteInqiry}/${id}`),
    deleteMessage: (id) => http.get(`${deleteMessage}/${id}`),
}

export default agentNoteApi
