/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import userHistoryAction from 'apis/historyUser'
import { handleMiddlewareLoadingAction } from 'store/helper'
import { payloadCreator } from 'views/utilities/helper'
export const actionApi = createAsyncThunk(
    'action',
    payloadCreator(userHistoryAction.handleActionApi)
)
export const getIP = createAsyncThunk(
    'IP',
    (query) => {
        const resp = handleMiddlewareLoadingAction(() => userHistoryAction.getIP(query), 'IP')
        return resp
    }
)
export const getMessage = createAsyncThunk(
    'user/message',
    (query) => {
        const resp = handleMiddlewareLoadingAction(() => userHistoryAction.getMessage(query), 'user/message')
        return resp
    }
)
export const getDeposit = createAsyncThunk(
    'user/deposit',
    (query) => {
        const resp = handleMiddlewareLoadingAction(() => userHistoryAction.getDepositMoney(query), 'user/deposit')
        return resp
    }
)
export const getWithdraw = createAsyncThunk(
    'user/withdraw',
    (query) => {
        const resp = handleMiddlewareLoadingAction(() => userHistoryAction.getWithdrawMoney(query), 'user/withdraw')
        return resp
    }
)
export const getPaymentHistory = createAsyncThunk(
    'payment-history',
    (query) => {
        const resp = handleMiddlewareLoadingAction(() => userHistoryAction.getPaymentHistory(query), 'payment-history')
        return resp
    }
)
export const getCollectHistory = createAsyncThunk(
    'collect-history',
    (query) => {
        const resp = handleMiddlewareLoadingAction(() => userHistoryAction.getCollectHistory(query), 'collect-history')
        return resp
    }
)
export const getPointExchangeHistory = createAsyncThunk(
    'point-exchange-history',
    (query) => {
        const resp = handleMiddlewareLoadingAction(() => userHistoryAction.getPointExchangeHistory(query), 'point-exchange-history')
        return resp
    }
)
export const getProfitandLose = createAsyncThunk(
    'profit-lose',
    (query) => {
        const resp = handleMiddlewareLoadingAction(() => userHistoryAction.getProfitLose(query), 'profit-lose')
        return resp
    }
)
const userHisAction = createSlice({
    name: 'userHistory',
    initialState: {
        dataIp: null,
        dataMessage: null,
        dataTotal: null,
        header: null,
        actionStatus: null
    },
    extraReducers: {
        [getIP.fulfilled]: (state, action) => {
            // state.dataIp = action.payload
            state.dataTotal = action.payload
            state.header = 1
        },
        [getMessage.fulfilled]: (state, action) => {
            // state.dataMessage = action.payload
            state.dataTotal = action.payload
            state.header = 2
        },
        [getDeposit.fulfilled]: (state, action) => {
            state.dataTotal = action.payload
            state.header = 4
        },
        [getWithdraw.fulfilled]: (state, action) => {
            state.dataTotal = action.payload
            state.header = 5
        },
        [getPaymentHistory.fulfilled]: (state, action) => {
            state.dataTotal = action.payload
            state.header = 6
        },
        [getCollectHistory.fulfilled]: (state, action) => {
            state.dataTotal = action.payload
            state.header = 7
        },
        [getPointExchangeHistory.fulfilled]: (state, action) => {
            state.dataTotal = action.payload
            state.header = 8
        },
        [getProfitandLose.fulfilled]: (state, action) => {
            state.dataTotal = action.payload
            state.header = 3
        },
        [actionApi.fulfilled]:(state, action) => {
            state.actionStatus = action.payload
            state.dataTotal = null
        },
        [actionApi.rejected]:(state, action) => {
            state.actionStatus = action.payload
        },
    }
})
const userHistoryReducer = userHisAction.reducer
export default userHistoryReducer