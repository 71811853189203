import http from 'views/utilities/http'
const URLList = 'evo/member/point/change'
const URL = 'agent/'
const actionPointChangeApi = {
    getPointChangeApi(params) {
        return http.get(`${URLList}`)
    },
    getAddPointChangeApi(params) {
        return http.post(`${URL}${params.url}`, (params = { ...params }))
    },
    getOutPointChangeApi(params) {
        return http.post(`${URL}${params.url}`, (params = { ...params }))
    }
}

export default actionPointChangeApi
