import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        loader: {
            isLoading: false
        }
    },
    reducers: {
        startLoad(state, {payload, type}){
            state.loader = {
                ...state.loader,
                isLoading: true,
                [payload]: true
            }
        },
        endLoad(state, {payload, type}){
            state.loader = {
                ...state.loader,
                isLoading: false,
                [payload]: false
            }
        }
    }
})

export const actions = loaderSlice.actions
export default loaderSlice.reducer
