import * as React from 'react'
import Button from '@mui/material/Button'
import { styled, useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, TextField } from '@mui/material'
import ButtonCustom from 'ui-component/button/ButtonCustom'
import { formatNumber } from 'views/utilities/moneyConvert'
import { useEffect } from 'react'
import { exchangePoint, withdrawMoney } from 'store/modal.slice'
import { useDispatch, useSelector } from 'react-redux'
import { useToastControl } from 'views/utilities/toastControl'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}))

// eslint-disable-next-line react/prop-types
export default function ModalExchangePoint({ open, setOpen, me }) {
    const [value, setValue] = React.useState(0)
    const theme = useTheme()
    const { toastControl } = useToastControl()
    const { exchangePointStatus } = useSelector((state) => state.modalReducer)
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'))
    const handleClose = () => {
        setOpen(false)
    }
    const handleInputChange = (e) => {
        const rawValue = e
        const numericValue = parseFloat(rawValue.replace(/,/g, '')) || 0
        setValue(numericValue)
    }
    const dispatch = useDispatch()
    useEffect(() => {}, [value])
    useEffect(() => [me])
    const handleExchangePoint = () => {
        if (value > 0) {
            dispatch(exchangePoint({ money: value }))
        }
    }
    useEffect(() => {
        if (exchangePointStatus) {
            if (exchangePointStatus?.status === 200) {
                toastControl(true, 'success', exchangePointStatus?.message)
                setOpen(false)
            }
            if (exchangePointStatus?.status !== 200) {
                toastControl(true, 'error', exchangePointStatus?.message)
            }
        }
    }, [exchangePointStatus])
    return (
        <>
            {me.data && (
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby='responsive-dialog-title'
                    open={open}
                >
                    <Typography
                        variant='h3'
                        sx={{ m: 0, p: 2 }}
                        id='customized-dialog-title'
                    >
                        포인트 =&gt; 보유머니로 전환
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ padding: '40px', minWidth: '600px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                my: 2
                            }}
                        >
                            {/* <Typography
                                component='body1'
                                variant='body1'
                                sx={{ m: 0, p: 0 }}
                                id='customized-dialog-title'
                            >
                                최소 전환 가능 금액:
                            </Typography> */}
                            {/* <TextField
                                // type="number"
                                placeholder=''
                                id='outlined-read-only-input'
                                value='5,000'
                                onChange={(e) => {
                                    handleInputChange(e.target.value)
                                }}
                                inputProps={{
                                    disabled: true
                                }}
                            /> */}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                my: 2
                            }}
                        >
                            <Typography
                                component='body1'
                                variant='body1'
                                sx={{ m: 0, p: 0 }}
                                id='customized-dialog-title'
                            >
                                변환 금액:
                            </Typography>
                            <TextField
                                // type="number"
                                placeholder='10만원 이상'
                                id='outlined-read-only-input'
                                value={
                                    formatNumber(
                                        value > me?.data?.mb_point
                                            ? me?.data?.mb_point
                                            : value
                                    ) || ''
                                }
                                onChange={(e) => {
                                    handleInputChange(e.target.value)
                                }}
                            />
                        </Box>
                    </Box>

                    <DialogActions sx={{ margin: '0 auto' }}>
                        <Button
                            autoFocus
                            sx={{
                                color: '#fff',
                                backgroundColor: '#844fc1',
                                borderColor: '#844fc1',
                                paddingLeft: '30px',
                                paddingRight: '30px',
                                '&:hover': {
                                    backgroundColor: '#5f3491',
                                    borderColor: '#844fc1'
                                }
                            }}
                            onClick={handleExchangePoint}
                        >
                            전환
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            )}
        </>
    )
}
