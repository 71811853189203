/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getMeApi from 'apis/getMe'
import { payloadCreator } from 'views/utilities/helper'
import { payloadCreatorAndLoading } from './helper'

export const getMe = createAsyncThunk('me', payloadCreator(getMeApi.getMe))

export const getUserInfor = createAsyncThunk(
    'user/infor',
    payloadCreatorAndLoading(getMeApi.getUserInfor, 'user/infor')
)
const me = createSlice({
    name: 'accountDetail',
    initialState: {
        data: {},
        userInfor: {}
    },
    extraReducers: {
        [getMe.fulfilled]: (state, action) => {
            state.data = action.payload.data
        },
        [getUserInfor.fulfilled]: (state, action) => {
            state.userInfor = action.payload
        }
    }
})
const meReducer = me.reducer
export default meReducer
