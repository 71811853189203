import http from 'views/utilities/http'

const URL = 'evo/list_member?connectUser=Y'
const actionUserConnectorApi = {
    getUserConnector() {
        return http.get(`${URL}`)
    }
}

export default actionUserConnectorApi
