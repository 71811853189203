import http from 'views/utilities/http'

const messageEndpoint = 'message'
const deleteMessage = `message/delete`

const messageApi = {
    getMessage: (query) => http.get(`${messageEndpoint}`, {params: query}),
    getMessageDetail: (id) => http.get(`${messageEndpoint}/${id}`),
    deleteMessage: (id) => http.get(`${deleteMessage}/${id}`),
}

export default messageApi
