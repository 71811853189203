import { Link } from 'react-router-dom'

// material-ui
import { ButtonBase, Grid, Typography } from '@mui/material'
// project imports
import config from 'config'
// import Logo from 'ui-component/Logo';
import Logo from 'assets/images/logo-major.png'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
// ==============================|| MAIN LOGO ||============================== //

const useStyle = makeStyles({
    box: {
        maxWidth: 100
    },
    boxImg: {
        width: '100%'
    }
})

const LogoSection = () => {
    const { data } = useSelector((state) => state.meReducer)
    const style = useStyle()
    console.log(style)
    return (
        <>
            <ButtonBase disableRipple component={Link} to={'/dashboard'}>
                {/* <div className={style.box}>
                    <img className={style.boxImg} src={Logo} alt='' />
                </div> */}
            </ButtonBase>
            <br />
            <Grid mt={1} container gap={1}>
                <Typography fontWeight='bold'>ID:</Typography>
                <Typography fontWeight='bold'>{data.mb_id || '...'}</Typography>
            </Grid>
        </>
    )
}

export default LogoSection
