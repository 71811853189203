import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import actionPointChangeApi from 'apis/actionPointChangeApi.api'
import { payloadCreatorAndLoading } from 'store/helper'

export const addPointChange = createAsyncThunk(
    'add/point/change',
    payloadCreatorAndLoading(actionPointChangeApi.getAddPointChangeApi)
)

export const outPointChange = createAsyncThunk(
    'out/point/change',
    payloadCreatorAndLoading(actionPointChangeApi.getOutPointChangeApi)
)

const pointChangeCommon = createSlice({
    name: 'pointChangeCommon123',
    initialState: {
        pointChange: {}
    },
    extraReducers: {
        [addPointChange.fulfilled]: (state, action) => {
            state.pointChange = action.payload
        },
        [outPointChange.fulfilled]: (state, action) => {
            state.pointChange = action.payload
        },
        [addPointChange.rejected]: (state, action) => {
            state.pointChange = action.payload
        },
        [outPointChange.rejected]: (state, action) => {
            state.pointChange = action.payload
        }
    }
})

const pointChangeReducer = pointChangeCommon.reducer
export default pointChangeReducer
