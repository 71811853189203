import React from 'react';
import Button from '@mui/material/Button';

function ButtonCustom({ title, bgcolor, colorText, onClick }) {
    return (
        <Button onClick={onClick} sx={{ padding: '5px', backgroundColor: bgcolor }} variant="contained">
            {title}
        </Button>
    );
}

export default ButtonCustom;
