import http from 'views/utilities/http'

const slotEndpoint = 'evo/betting/casino-slot'

const historyApi = {
    getManagementBetting: (query) =>
        http.get(`${slotEndpoint}`, { params: query })
}

export default historyApi
