/* eslint-disable prettier/prettier */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import bettingApi from 'apis/betting&rolling.api';
import { handleMiddlewareLoadingAction, payloadCreatorAndLoading } from 'store/helper';
export const getBettingSlot = createAsyncThunk(
    'bettingSlot/detail',
     (query) => {
        const resp =  handleMiddlewareLoadingAction(()=>bettingApi.getBettingSlot(query),'bettingSlot/detail') ;
        return resp
    }
)

export const getBettingCasino = createAsyncThunk(
    'bettingCasino/detail',
    (query) => {
        const resp = handleMiddlewareLoadingAction(()=>bettingApi.getBettingCasino(query),'bettingCasino/detail');
        return resp
    }
)
export const getBetting = createAsyncThunk(
    'betting',
    (query) => {
        const resp = handleMiddlewareLoadingAction(()=>bettingApi.getBettingTotal(query),'betting');
        return resp
    }
)

const bettingSlice = createSlice({
    name: 'betting',
    initialState: {
        betting_slot: undefined,
        betting_casino:undefined,
        total:undefined
    },
    extraReducers: {
        [getBettingSlot.fulfilled]: (state, action) => {
            state.betting_slot = action.payload
        },
        [getBettingCasino.fulfilled]: (state, action) => {
                state.betting_casino = action.payload
        }, 
        [getBetting.fulfilled]: (state, action) => {
            state.total = action.payload
        }, 
    }
})
const bettingSliceReducer = bettingSlice.reducer
export default bettingSliceReducer

