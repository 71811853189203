/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import actionMoney from 'apis/actionMoney'
import agentHelp from 'apis/help'
import { payloadCreator } from 'views/utilities/helper'

export const depositMoney = createAsyncThunk(
    'deposit/money',
    payloadCreator(actionMoney.depositMoney)
)
export const withdrawMoney = createAsyncThunk(
    'withdraw/money',
    payloadCreator(actionMoney.withdrawMoney)
)
export const exchangePoint = createAsyncThunk(
    'exchange/point',
    payloadCreator(actionMoney.exchangePoint)
)
export const helpAgent = createAsyncThunk(
    'agent/help',
    payloadCreator(agentHelp.sendRequest)
)
const modalAction = createSlice({
    name: 'modalAction',
    initialState: {
        depositStatus: null,
        withdrawStatus: null,
        exchangePointStatus: null,
        helpStatus: null
    },
    extraReducers: {
        [depositMoney.fulfilled]: (state, action) => {
            state.depositStatus = action.payload
        },
        [depositMoney.rejected]: (state, action) => {
            state.depositStatus = action.payload
        },
        [withdrawMoney.fulfilled]: (state, action) => {
            state.withdrawStatus = action.payload
        },
        [withdrawMoney.rejected]: (state, action) => {
            state.withdrawStatus = action.payload
        },
        [exchangePoint.fulfilled]: (state, action) => {
            state.exchangePointStatus = action.payload
        },
        [exchangePoint.rejected]: (state, action) => {
            state.exchangePointStatus = action.payload
        },
        [helpAgent.fulfilled]: (state, action) => {
            state.helpStatus = action.payload
        },
        [helpAgent.rejected]: (state, action) => {
            state.helpStatus = action.payload
        }
    }
})
const modalReducer = modalAction.reducer
export default modalReducer